<template>
  <div class="van-main use-credit-detail">
    <div class="area-title">
      <p class="title">
        用信台账详情
      </p>
    </div>
    <div class="van-common-detail">
      <van-cell-group>
        <van-cell title="资金来源" :value="utils.statusFormat(useCreditForm.sourceFunds, 'FundsSource')" />
        <van-cell title="授信主体" :value="utils.isEffectiveCommon(useCreditForm.creditSubject)" />
        <van-cell title="授信机构" :value="utils.isEffectiveCommon(useCreditForm.creditInstitutions)" />
        <van-cell title="分支机构" :value="utils.isEffectiveCommon(useCreditForm.branch)" />
        <van-cell title="授信额度（元）" :value="utils.moneyFormat(useCreditForm.creditLine)" />
        <van-cell title="借款品种" :value="utils.isEffectiveCommon(useCreditForm.loanVarieties_Name)" />
        <van-cell title="借款合同名称" :value="utils.isEffectiveCommon(useCreditForm.contractName)" />
        <van-cell title="借款合同编号" :value="utils.isEffectiveCommon(useCreditForm.contractNo)" />
        <van-cell title="借款本金（元）" :value="utils.moneyFormat(useCreditForm.amountMoney)" />
        <van-cell title="放款日期" :value="utils.dateFormat(useCreditForm.startDate)" />
        <van-cell title="到期日期" :value="utils.dateFormat(useCreditForm.endDate)" />
        <van-cell title="期限（日）" :value="utils.isEffectiveCommon(useCreditForm.term)" />
        <van-cell title="名义利率" :value="utils.isEffectiveCommon(useCreditForm.nominalInterestRate)" />
        <van-cell title="实际利率" :value="utils.isEffectiveCommon(useCreditForm.realInsterestRate)" />
        <van-cell title="已还款金额（元）" :value="utils.moneyFormat(useCreditForm.repaymentAmount)" />
        <van-cell title="未还款金额（元）" :value="utils.moneyFormat((useCreditForm.amountMoney || 0) - (useCreditForm.repaymentAmount || 0))" />
      </van-cell-group>
    </div>
    <back-button />
  </div>
</template>

<script>
import { Cell, CellGroup, Divider, Button, Toast } from 'vant'
import BackButton from '@/components/back-button'
export default {
  components: {
    BackButton,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Divider.name]: Divider,
    [Toast.name]: Toast
  },
  data () {
    return {
      id: this.$route.query.id,
      useCreditForm: {
      }
    }
  },
  computed: {
  },
  created () {
    this.getDetail()
  },
  methods: {
    getDetail () {
      this.useCreditForm = JSON.parse(window.sessionStorage.getItem('useCreditDetail'))
      // this.api.fundManagement.external.getDetail(this.$route.query.id).then(result => {
      //   this.externalForm = result.data.value
      //   const fileList = []
      //   if (this.externalForm.fileDetailInfoVo) {
      //     fileList.push(this.externalForm.fileDetailInfoVo)
      //   }
      //   this.fileList = fileList
      // }).finally(() => {
      // })
    }
  }
}
</script>

<style lang="less" scoped>
.use-credit-detail {
  margin-bottom: 60px;
}
</style>
