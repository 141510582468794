var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "van-main use-credit-detail" },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "van-common-detail" },
        [
          _c(
            "van-cell-group",
            [
              _c("van-cell", {
                attrs: {
                  title: "资金来源",
                  value: _vm.utils.statusFormat(
                    _vm.useCreditForm.sourceFunds,
                    "FundsSource"
                  )
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "授信主体",
                  value: _vm.utils.isEffectiveCommon(
                    _vm.useCreditForm.creditSubject
                  )
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "授信机构",
                  value: _vm.utils.isEffectiveCommon(
                    _vm.useCreditForm.creditInstitutions
                  )
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "分支机构",
                  value: _vm.utils.isEffectiveCommon(_vm.useCreditForm.branch)
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "授信额度（元）",
                  value: _vm.utils.moneyFormat(_vm.useCreditForm.creditLine)
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "借款品种",
                  value: _vm.utils.isEffectiveCommon(
                    _vm.useCreditForm.loanVarieties_Name
                  )
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "借款合同名称",
                  value: _vm.utils.isEffectiveCommon(
                    _vm.useCreditForm.contractName
                  )
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "借款合同编号",
                  value: _vm.utils.isEffectiveCommon(
                    _vm.useCreditForm.contractNo
                  )
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "借款本金（元）",
                  value: _vm.utils.moneyFormat(_vm.useCreditForm.amountMoney)
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "放款日期",
                  value: _vm.utils.dateFormat(_vm.useCreditForm.startDate)
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "到期日期",
                  value: _vm.utils.dateFormat(_vm.useCreditForm.endDate)
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "期限（日）",
                  value: _vm.utils.isEffectiveCommon(_vm.useCreditForm.term)
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "名义利率",
                  value: _vm.utils.isEffectiveCommon(
                    _vm.useCreditForm.nominalInterestRate
                  )
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "实际利率",
                  value: _vm.utils.isEffectiveCommon(
                    _vm.useCreditForm.realInsterestRate
                  )
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "已还款金额（元）",
                  value: _vm.utils.moneyFormat(
                    _vm.useCreditForm.repaymentAmount
                  )
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "未还款金额（元）",
                  value: _vm.utils.moneyFormat(
                    (_vm.useCreditForm.amountMoney || 0) -
                      (_vm.useCreditForm.repaymentAmount || 0)
                  )
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("back-button")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "area-title" }, [
      _c("p", { staticClass: "title" }, [_vm._v(" 用信台账详情 ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }